<template>

    <div class="w-full h-full px-3 py-4">
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
            
            <All v-if="lastPage === 'grouptops'" model="Dimensionfiltertops" :immediate="true" :data.sync="topdimensions"
                 v-slot="{ data: datadimension, loading: loadingdimension}">

                <div v-if="!loadingdimension" class="h-auto px-2 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('dimension')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionfilter" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in datadimension"
                                    v-if="item.Top"
                                    :key="item.Key"
                                    :label="translate(item.Key)"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="dimensionfilter" v-model="dimensionfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in datadimension" :key="index" v-if="el.Top" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Key)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-else-if="(lastPage === 'comercialtops' || lastPage === 'representanttops' || lastPage === 'comercialclienttops' ||
                lastPage === 'representantclienttops') && zoneSelected === 'Nacional'" model="Dimensionfiltertops"
                 :immediate="true" :data.sync="topdimensions" v-slot="{ data: datadimension, loading: loadingdimension}">
                <div v-if="!loadingdimension" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('dimension')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionfilter" :placeholder="$t('select')" class="w-full">
                                <el-option
                                v-for="item in datadimension"
                                v-if="item.Top && item.Key !== 'Country' && item.Key !== 'Company' && item.Key !== 'Continent'"
                                :key="item.Key"
                                :label="translate(item.Name)"
                                :value="item.Key"
                                class="w-full">
                                </el-option>
                            </el-select> -->
                            <select name="dimensionfilter" v-model="dimensionfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in datadimension" :key="index" v-if="el.Top && el.Key !== 'Country' && el.Key !== 'Company' && el.Key !== 'Continent'" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-else-if="(lastPage === 'comercialtops' || lastPage === 'representanttops' || lastPage === 'comercialclienttops'
                || lastPage === 'representantclienttops') && zoneSelected === 'Exportación'" model="Dimensionfiltertops" :immediate="true"
                 :data.sync="topdimensions" v-slot="{ data: datadimension, loading: loadingdimension}" >
                <div v-if="!loadingdimension" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('dimension')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionfilter" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in datadimension"
                                    v-if="item.Top && item.Key !== 'Province' && item.Key !== 'Company'"
                                    :key="item.Key"
                                    :label="translate(item.Key)"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="dimensionfilter" v-model="dimensionfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in datadimension" :key="index" v-if="el.Top && el.Key !== 'Province' && el.Key !== 'Company'" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Key)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-else-if="(lastPage === 'comercialclienttops' || lastPage === 'representantclienttops')" model="Dimensionfiltertops"
                 :immediate="true" :data.sync="topdimensions" v-slot="{ data: datadimension, loading: loadingdimension}" >
                <div v-if="!loadingdimension" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('dimension')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionfilter" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in datadimension"
                                    v-if="item.Top && item.Key !== 'Province' && item.Key !== 'Company' && item.Key !== 'Country' &&
                                    item.Key !== 'Client' && item.Key !== 'Continent'"
                                    :key="item.Key"
                                    :label="item.Name"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="dimensionfilter" v-model="dimensionfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in datadimension" :key="index" v-if="el.Top && el.Key !== 'Province' && el.Key !== 'Company' && el.Key !== 'Country' && el.Key !== 'Client' && el.Key !== 'Continent'" :value="el.Key" :class="'text-dfont-'+mode">{{el.Name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-else model="Dimensionfiltertops" :immediate="true" :data.sync="topdimensions"
                v-slot="{ data: datadimension, loading: loadingdimension}">
                <div v-if="!loadingdimension" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('dimension')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionfilter" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in datadimension"
                                    v-if="item.Top && item.Key !== 'Company'"
                                    :key="item.Key"
                                    :label="translate(item.Key)"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="dimensionfilter" v-model="dimensionfilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in datadimension" :key="index" v-if="el.Top && el.Key !== 'Company'" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Key)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-if="dimensionfilter === 'Client'" model="TypeClientsTop" :immediate="true" v-slot="{data :datatypeclient}">
                <div class="h-8 w-full px-2 mt-1">
                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                        <!-- <el-select v-model="typeclient" :placeholder="$t('select')" class="w-full">
                            <el-option
                                v-for="item in datatypeclient"
                                :key="item.ClientType"
                                :label="item.ClientType"
                                :value="item.ClientType"
                                class="w-full"/>
                        </el-select> -->
                        <select name="typeclient" v-model="typeclient" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in datatypeclient" :key="index" :value="el.ClientType" :class="'text-dfont-'+mode">{{el.ClientType}}</option>
                        </select>
                    </div>
                </div>
            </All>
            <All v-if="user.rol.Name !== 'representante'" model="Groupdimensions" :immediate="true"
                 :data.sync="topindicators" v-slot="{ data, loading}" >
                <div v-if="!loading" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('indicator')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-if="rol !== 'Representante'" v-model="dimensionOption" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in data"
                                    :key="item.Key"
                                    :label="translate(item.Name)"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select>
                            <el-select v-else v-model="dimensionOption" :placeholder="$t('select')" class="w-full">
                                <el-option
                                        v-for="item in data"
                                        :key="item.Key"
                                        v-if="item.Name !== 'Margen'"
                                        :label="translate(item.Name)"
                                        :value="item.Key"
                                        class="w-full"/>
                            </el-select> -->
                            <select v-if="rol !== 'Representante'" name="dimensionOption" v-model="dimensionOption" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in data" :key="index" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Name)}}</option>
                            </select>
                            <select v-else name="dimensionOption" v-model="dimensionOption" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in data" :key="index" v-if="el.Name !== 'Margen'" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <All v-else model="Groupdimensions" :immediate="true" :data.sync="topindicators" v-slot="{ data, loading}">
                <div v-if="!loading" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('indicator')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionOption" :placeholder="$t('select')" class="w-full">
                                <el-option
                                    v-for="item in data"
                                    v-if="item.Key !== 3"
                                    :key="item.Key"
                                    :label="item.Name"
                                    :value="item.Key"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="dimensionOption" v-model="dimensionOption" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in data" :key="index" v-if="el.Key !== 3" :value="el.Key" :class="'text-dfont-'+mode">{{el.Name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <div v-show="dimensionfilter !== 'Country' && dimensionfilter !== 'Province' && dimensionfilter !== 'Continent' &&
                lastPage !== 'representantclienttops' && lastPage !== 'comercialclienttops'" class="h-auto">
                <div v-if="lastPage !== 'comercialtops' && lastPage !== 'representantclienttops'"
                    class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('place')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="typePlace" :placeholder="$t('selectALocation')" class="w-full">
                                <el-option
                                    v-for="item in places"
                                    :key="item.value + item.name"
                                    :label="translate(item.name)"
                                    :value="item.value"
                                    class="w-full"/>
                                <el-option
                                    :key="'all'"
                                    :label="translate('all')"
                                    :value="null"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="typePlace" v-model="typePlace" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in places" :key="el.value+el.name" :value="el.value" :class="'text-dfont-'+mode">{{translate(el.name)}}</option>
                                <option :key="'all'" :value="null">{{translate('all')}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-else-if="lastPage === 'representantclienttops'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-sm text-gray">{{$t('place')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="typePlace" :placeholder="$t('selectALocation')" class="w-full">
                                <el-option
                                    v-for="item in places"
                                    :key="item.value + item.name"
                                    :label="'item.name'"
                                    :value="item.value"
                                    class="w-full"/>
                                <el-option
                                    :key="'all'"
                                    :label="'Todos'"
                                    :value="null"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="typePlace" v-model="typePlace" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in places" :key="el.value+el.name" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                                <option :key="'all'" :value="null">{{translate('all')}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-else-if="zoneSelected === 'Nacional'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('place')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="typePlace" :placeholder="$t('selectALocation')" class="w-full">
                                <el-option
                                    v-for="item in places"
                                    v-if="item.value === 'Provincetops'"
                                    :key="item.value + item.name"
                                    :label="item.name"
                                    :value="item.value"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="typePlace" v-model="typePlace" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in places" :key="el.value+el.name" v-if="el.value == 'Provincetops'" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-else-if="zoneSelected === 'Exportación'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('place')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="typePlace" :placeholder="$t('selectALocation')" class="w-full">
                                <el-option
                                    v-for="item in places"
                                    v-if="item.value !== 'Provincetops'"
                                    :key="item.value + item.name"
                                    :label="translate(item.name)"
                                    :value="item.value"
                                    class="w-full"/>
                            </el-select> -->
                            <select name="typePlace" v-model="typePlace" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in places" :key="el.value+el.name" v-if="el.value !== 'Provincetops'" :value="el.value" :class="'text-dfont-'+mode">{{translate(el.name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <All v-show="typePlace != null" :model="typePlace" :immediate="false" :data.sync="topplaces"
                    v-slot="{data: dataPlace, loading: loadingplaces}" ref="refplaces">
                    <div v-if="!loadingplaces" class="h-auto px-2 mt-2 flex flex-col justify-center items-start">
                        <div class="h-8 w-full">
                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                <!-- <el-select v-model="place" :placeholder="$t('select')" filterable class="w-full">
                                    <el-option
                                        v-for="item in dataPlace"
                                        :key="item.Id"
                                        :label="translate(item.Name || item.idLink)"
                                        :value="item.Id"
                                        class="w-full"/>
                                    <el-option
                                        :key="'all'"
                                        :label="translate('Todos')"
                                        :value="null"
                                        class="w-full"/>
                                </el-select> -->
                                <select name="place" v-model="place" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in dataPlace" :key="el.Id" :value="el.Id" :class="'text-dfont-'+mode">{{translate(el.Name || el.idLink)}}</option>
                                    <option :key="'all'" :value="null" :class="'text-dfont-'+mode">{{translate('Todos')}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </All>
            </div>
            <div class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                        <!-- <el-select v-model="period" :placeholder="$t('select')" class="w-full">
                            <el-option
                                v-for="item in periods"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"/>
                        </el-select> -->
                        <select name="period" v-model="period" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- nuevo selector -->
             <!-- <div v-if="period == 'month'"  class="h-auto px-2 mt-2 flex flex-col justify-center items-start">
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <select name="month" v-model="month" @change='event => updateMonth(event.target.value)' class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option :value="el.value" v-for="(el) in months" :key="el.value" >{{el.name}}</option>
                            </select>
                        </div>
                    </div>
            </div> -->
            <!-- nuevo selector -->
            
            <filter-months v-if="period == 'month'"   :mode='mode'/>
            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center cursor-pointer" @click="applyfilters()">
                    <span class="text-lg text-white">{{ $t('apply') }}</span>
                </div>
            </div>
            <div class="h-6"></div>
        </div>
    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components: {
        All
    },
    data() {
        return {
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
              months: [
                { 
                    name: this.$t("january"),
                    value: 1
                },
                { 
                    name: this.$t("february"),
                    value: 2
                },
                { 
                    name: this.$t("march"),
                    value: 3
                },
                { 
                    name: this.$t("april"),
                    value: 4
                },
                { 
                    name: this.$t("may"),
                    value: 5
                },
                { 
                    name: this.$t("june"),
                    value: 6
                },
                 { 
                    name: this.$t("july"),
                    value: 7
                },
                { 
                    name: this.$t("august"),
                    value: 8
                },
                { 
                    name: this.$t("september"),
                    value: 9
                },
                { 
                    name: this.$t("october"),
                    value: 10
                },
                { 
                    name: this.$t("november"),
                    value: 11
                },
                { 
                    name: this.$t("december"),
                    value: 12
                }
            ],
            period: '',
            month: '',
            dimensionOption: null,
            dimensions: [],
            typePlace: null,
            places: [
                { 
                    name: 'Continente',
                    value: 'Continenttops'
                },
                { 
                    name: 'Pais',
                    value: 'Countrytops'
                },
                { 
                    name: 'Provincias',
                    value: 'Provincetops'
                }
            ],
            place: null,
            dimensionfilter: null,
            topdimensions: null,
            topplaces: null,
            topindicators: null,
            typeclient: null
        }
    },
    methods: {
        updateMonth(month){
            actions.setMonth(month)
        },
        translate(word) {
            switch (word) {
                case 'EUROPA':
                    return this.$t("europe")
                    break;
                case 'AMERICA CENTRAL':
                    return this.$t("centralOfAmerica")
                    break;
                case 'AMERICA NORTE':
                    return this.$t("northOfAmerica")
                    break;
                case 'AMERICA SUR':
                    return this.$t("southOfAmerica")
                    break;
                case 'ASIA':
                    return this.$t("asia")
                    break;
                case 'OCEANIA':
                    return this.$t("oceania")
                    break;
                case 'AFRICA':
                    return this.$t("africa")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
                case 'Continente':
                    return this.$t("continent")
                    break;
                case 'Pais':
                    return this.$t("country")
                    break;
                case 'Provincias':
                    return this.$t("spanishProvinces")
                    break;
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Marcas':
                    return this.$t("brands")
                    break;
                case 'Company':
                    return this.$t("brands")
                    break;
                case 'all':
                    return this.$t("all")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Metros cuadrados':
                    return this.$t("squareMeters")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
                case 'Employee':
                    return this.$t("customers")
                    break;
                case 'meters':
                    return this.$t("meters")
                    break;
                case 'Country':
                    return this.$t("countries")
                    break;
                case 'Continent':
                    return this.$t("continents")
                    break;
                case 'Province':
                    return this.$t("spanishProvinces")
                    break;
                case 'SuperFamily':
                    return this.$t("superFamily")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'Format':
                    return this.$t("formats")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Model':
                    return this.$t("models")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                case 'Color':
                    return this.$t("colors")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Quality':
                    return this.$t("quality")
                    break;
                case 'Calidad':
                    return this.$t("quality")
                    break;
                case 'Client':
                    return this.$t("customers")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                default:
                    return word
                    break;
        }
        },
        applyfilters() {
            if (this.dimensionfilter === 'Client') { actions.setTypeclienttops(this.typeclient) }
            else { actions.setTypeclienttops(null) }
            actions.setTopDimension(this.dimensionfilter);
            for (let index = 0; index < this.topdimensions.length; index++) {
                if (this.topdimensions[index].Key === this.dimensionfilter) {
                    actions.setTopDimensionName(this.topdimensions[index].Name)
                }
            }
            actions.setTopIndicator(this.dimensionOption);
            for (let index = 0; index < this.topindicators.length; index++) {
                if (this.topindicators[index].Key === this.dimensionOption) {
                    actions.setTopIndicatorName(this.topindicators[index].Name)
                }
            }
            actions.setTopPeriod(this.period);
            if (this.dimensionfilter !== 'Country' && this.dimensionfilter !== 'Province' && this.place != null
                && this.typePlace != null) {
                    switch (this.typePlace) {
                        case 'Continenttops':
                            actions.setTopTypePlace('Continent')
                            actions.setActualTopTypePlace('Continenttops')
                            break;
                        case 'Countrytops':
                            actions.setTopTypePlace('Country')
                            actions.setActualTopTypePlace('Countrytops')
                            break;
                        case 'Provincetops':
                            actions.setTopTypePlace('Province')
                            actions.setActualTopTypePlace('Provincetops')
                            break;
                    }
                actions.setTopPlace(this.place)
                for (let index = 0; index < this.topplaces.length; index++) {
                    if (this.topplaces[index].Id == this.place) {
                        actions.setTopPlaceName(this.topplaces[index].Name)
                    }
                }
            }
            else {
                if (this.typePlace != null) {
                    actions.setTopPlace(null)
                    switch (this.typePlace) {
                        case 'Continenttops':
                            actions.setTopTypePlace('Continent')
                            actions.setActualTopTypePlace('Continenttops')
                            actions.setTopPlaceName('Todos los continentes')
                            break;
                        case 'Countrytops':
                            actions.setTopTypePlace('Country')
                            actions.setActualTopTypePlace('Countrytops')
                            actions.setTopPlaceName('Todos los paises')
                            break;
                        case 'Provincetops':
                            actions.setTopTypePlace('Province')
                            actions.setActualTopTypePlace('Provincetops')
                            actions.setTopPlaceName('Todos las Provincias')
                            break;
                    }
                }
                else {
                    actions.setTopTypePlace(null)
                    actions.setTopPlace(null)
                    actions.setTopPlaceName('Todos')
                    actions.setActualTopTypePlace(null)
                }
            }
            this.$router.go(-1);
            // this.$router.push({name:this.lastPage})
        }
    },
    computed: {
        rol() {return state.user.rol.Name },
        lastPage() { return state.lastPage },
        zoneSelected() { return state.zoneSelected },
        user() { return state.user },
        mode(){
            return state.mode
        }
    },
    watch: {
        typePlace(n, o) {
            if(n != null){
                setTimeout(() => {
                    this.$refs['refplaces'].request();
                    if (o == null) { this.place = state.topPlace }
                    else { this.place = null }
                }, 100);
            }
        },
        dimensionfilter(n, o) {
            if(n !== 'Country' && n !== 'Province'){
                setTimeout(() => {
                    this.$refs['refplaces'].request();
                }, 100);
            }
        }
    },
    mounted() {
        if (state.user.rol.Name !== 'Representante') { this.dimensionOption = state.topIndicator }
        else
            if (state.user.rol.Name === 'Representante' && state.topIndicator !== 3) {
                this.dimensionOption = 1
            }
            else { this.dimensionOption = state.topIndicator }
        this.period = state.topPeriod
        // this.month = state.month
        this.dimensionfilter = state.topDimension
        this.place = state.topPlace
        this.typePlace = state.actualTopTypePlace
        this.typeclient = state.typeclienttops
    }
}
</script>

<style>
    /* .el-input__inner {
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */
</style>